import { Switch } from "@mui/material";
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import colorPalette from "../../common/colors";
import defaultSize from "./constants/defaultSizes";

function ToggleButton(props) {
    const handleClick = (value) => {
        console.log(value);
    }
    return (
        <Switch
            onchange={(value) => handleClick(value)}
            isChecked={props.isChecked}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            sx={(theme) => ({
                height: {
                    xs: defaultSize.sm.height,
                    sm: defaultSize.sm.height,
                    md: defaultSize.md.height,
                    lg: defaultSize.lg.height,
                    lx: defaultSize.xl.height,
                },
                width: {
                    xs: defaultSize.sm.width,
                    sm: defaultSize.sm.width,
                    md: defaultSize.md.width,
                    lg: defaultSize.lg.width,
                    lx: defaultSize.xl.width,
                },
                padding: 0,
                '& .MuiSwitch-switchBase': {
                    padding: 0,
                    transitionDuration: '300ms',
                    '&.Mui-checked': {
                        transform: {
                            xs: `translateX(${defaultSize.sm.width - (defaultSize.sm.height / 2)})`,
                            sm: `translateX(${defaultSize.sm.width - (defaultSize.sm.height / 2)})`,
                            md: `translateX(${defaultSize.sm.width - (defaultSize.sm.height / 2)})`,
                            lg: `translateX(${defaultSize.sm.width - (defaultSize.sm.height / 2)})`,
                            lx: `translateX(${defaultSize.sm.width - (defaultSize.sm.height / 2)})`,
                        }
                        ,
                        color: '#fff',
                        '& + .MuiSwitch-track': {
                            backgroundColor: colorPalette.color.secondary.primaryTintSecondary,
                            opacity: 1,
                            border: 0    //`.6px solid ${colorPalette.sematics.lightBg.success}`
                        },
                        '& .MuiSwitch-thumb': {
                            backgroundColor: colorPalette.color.primary.primary,
                            border: 0,
                            boxShadow: '-2px 0px 3px #838ACD',
                            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="5" width="6" viewBox="0 0 5 6"><line x1="0.5" y1="0.5" x2="0.5" y2="5.5" stroke="${encodeURIComponent(
                                '#09471C',
                            )}" strokeLinecap="round"/><line x1="2.5" y1="0.5" x2="2.5" y2="5.5" stroke="${encodeURIComponent(
                                '#09471C',
                            )}" strokeLinecap="round"/><line x1="4.5" y1="0.5" x2="4.5" y2="5.5" stroke="${encodeURIComponent(
                                '#09471C',
                            )}" strokeLinecap="round"/></svg>')`
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.5
                        }
                    },
                    // '&.Mui-focusVisible .MuiSwitch-thumb': {     color: '#33cf4d',     border:
                    // '2px solid #fff', },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: theme
                            .palette
                            .grey[100]
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        opacity: 0.7
                    }
                },
                '& .MuiSwitch-thumb': {
                    boxSizing: 'border-box',
                    height: {
                        xs: defaultSize.sm.height,
                        sm: defaultSize.sm.height,
                        md: defaultSize.md.height,
                        lg: defaultSize.lg.height,
                        lx: defaultSize.xl.height,
                    },
                    width: {
                        xs: defaultSize.sm.height,
                        sm: defaultSize.sm.height,
                        md: defaultSize.md.height,
                        lg: defaultSize.lg.height,
                        lx: defaultSize.xl.height,
                    },
                    boxShadow: '2px 0px 3px #0000001A',
                    border: `1px solid ${colorPalette.neutrals.lightBg.quinary}`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="5" width="6" viewBox="0 0 5 6"><line x1="0.5" y1="0.5" x2="0.5" y2="5.5" stroke="${encodeURIComponent(
                        colorPalette.neutrals.lightBg.quinary,
                    )}" strokeLinecap="round"/><line x1="2.5" y1="0.5" x2="2.5" y2="5.5" stroke="${encodeURIComponent(
                        colorPalette.neutrals.lightBg.quinary,
                    )}" strokeLinecap="round"/><line x1="4.5" y1="0.5" x2="4.5" y2="5.5" stroke="${encodeURIComponent(
                        colorPalette.neutrals.lightBg.quinary,
                    )}" strokeLinecap="round"/></svg>')`
                },
                '& .MuiSwitch-track': {
                    border: `.6px solid ${colorPalette.neutrals.lightBg.quinary}`,
                    borderRadius: 26 / 2,
                    backgroundColor: '#D9D9D9',
                    opacity: 1,
                    transition: theme
                        .transitions
                        .create(['background-color'], { duration: 500 })
                }
            })} />);
}

ToggleButton.propTypes = {
    onChange: PropTypes.func.isRequired,
    isChecked: PropTypes.bool,
}

ToggleButton.defaultProps = {
    onChange: () => { },
    isChecked: false,
}

export default ToggleButton;