import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { ButtonWithLoadingState } from "../../../../auth-components";
import { OtpInput } from "./OtpInput";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import {
  loginService,
  mfaVerifyAuthApp,
  mfaVerifyUserAuth,
} from "../../../../services";
import jwtDecode from "jwt-decode";
import { sentryLogger, showApiErrorToaster } from "../../../../utils";

export const MfaVerifyYourAccount = ({ hasUseRecoveryCodeOption = false }) => {
  const theme = useTheme();
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate("/404");
    }
  }, []);

  const loginData = state?.loginData;
  const userId = state?.userId;
  const signInPageMfaEnrolled = state?.mfaEnrolled;
  const signInPageMfaToken = state?.mfaToken;
  const signInPageDeviceId = state?.deviceId;

  const [otp, setOtp] = useState("");
  const handleOtpChange = (newValue) => {
    setOtp(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);

  const callVerifyUserAuth = async ({ mfaToken, deviceId }) => {
    try {
      const response = await mfaVerifyUserAuth({
        mfaToken,
        deviceId,
        otp,
      });
      const accessToken = response?.data?.data?.accessToken;
      const refreshToken = response?.data?.data?.refreshToken;
      const expires = response?.data?.data?.expires;
      if (
        response?.data?.statusCode === 200 &&
        response?.data?.success === true &&
        accessToken !== undefined &&
        refreshToken !== undefined &&
        accessToken !== null &&
        refreshToken !== null
      ) {
        let decoded;
        try {
          decoded = jwtDecode(accessToken);
        } catch (e) {
          decoded = null;
        }
        if (decoded && decoded?.aud) {
          const redirectUrlWithParams = `${response?.data?.redirectUrl}/callback`;
          const newAud = `${decoded?.aud}`?.replace("-", "");
          document.cookie =
            `fe_refresh_${newAud}` +
            "=" +
            refreshToken +
            ";expires=" +
            expires +
            ";path=/;domain=.deskday.ai";
          window.location.href = redirectUrlWithParams;
          setIsLoading(false);
        } else {
          toast.error("Something went wrong. Please try again later.");
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
        throw response;
      }
    } catch (error) {
      showApiErrorToaster({ error, fallBackErrMsg: "MFA action failed" });
      setIsLoading(false);
      sentryLogger({
        error,
        functionName: "mfaVerifyUserAuth",
      });
    }
  };

  const callLoginServiceAndThenVerifyUserAuth = async () => {
    try {
      const response = await loginService(loginData);
      const mfaToken = response?.data?.data?.mfaToken;
      const deviceId =
        response?.data?.data?.mfaDevices?.authenticators?.[0]?.id;
      if (
        response?.data?.statusCode === 200 &&
        response?.data?.success === true
      ) {
        if (mfaToken && deviceId) {
          callVerifyUserAuth({ mfaToken, deviceId });
        } else {
          toast.error("Please enroll your device and try again.");
        }
      } else {
        setIsLoading(false);
        throw response;
      }
    } catch (error) {
      showApiErrorToaster({ error, fallBackErrMsg: "MFA action failed" });
      setIsLoading(false);
      sentryLogger({
        error,
        functionName: "loginService called after mfaVerifyAuthApp",
      });
    }
  };

  const handleMfaVerifyYourAccount = async () => {
    if (otp.length === 6) {
      setIsLoading(true);
      try {
        if (signInPageMfaEnrolled === false) {
          const response = await mfaVerifyAuthApp({
            otp,
            userId,
          });
          if (
            response?.data?.statusCode === 200 &&
            response?.data?.success === true
          ) {
            callLoginServiceAndThenVerifyUserAuth();
          } else {
            setIsLoading(false);
            throw response;
          }
        } else {
          callVerifyUserAuth({
            mfaToken: signInPageMfaToken,
            deviceId: signInPageDeviceId,
          });
        }
      } catch (error) {
        showApiErrorToaster({ error, fallBackErrMsg: "MFA action failed" });
        setIsLoading(false);
        sentryLogger({
          error,
          functionName: "mfaVerifyAuthApp",
        });
      }
    } else {
      toast.error("Please enter a valid otp.");
    }
  };

  useEffect(() => {
    if (otp.length === 6) {
      handleMfaVerifyYourAccount();
    }
  }, [otp]);

  return (
    <Stack direction="column" gap="24px" maxWidth={"60%"}>
      <Typography variant="h3">Verify Your Account</Typography>
      <Typography
        variant="body-large"
        color={theme.palette.lightBg.low}
        width={"100%"}
      >
        Enter the 6 digit code generated by your authenticator app
      </Typography>
      <OtpInput value={otp} onChange={handleOtpChange} />
      <Box display="flex">
        <ButtonWithLoadingState
          onClick={handleMfaVerifyYourAccount}
          isLoading={isLoading}
        >
          Continue
        </ButtonWithLoadingState>
      </Box>
      {hasUseRecoveryCodeOption && (
        <Divider color={theme.palette.neutral["015"]}>
          <Typography
            p="8px 16px"
            variant="body-large"
            color={theme.palette.lightBg.low}
          >
            or
          </Typography>
        </Divider>
      )}
      {hasUseRecoveryCodeOption && (
        <Typography
          p="8px 12px"
          textAlign={"center"}
          variant="button-large"
          color={theme.palette.primary.main}
          sx={{ cursor: "pointer" }}
          onClick={() => {}}
        >
          Use Recovery Code
        </Typography>
      )}
    </Stack>
  );
};
