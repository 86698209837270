import { Box, Stack, Typography } from "@mui/material";
import { ThemeProvider } from "../../theme/ThemeProvider";
import { mfaBg } from "../../assets/images";
import { ButtonWithLoadingState } from "../Button";
import { useNavigate } from "react-router-dom";
export const ErrorScreenLayout = ({
  title,
  subtitle,
  hasRedirectToLoginBtn = true,
  errorImg,
}) => {
  const navigate = useNavigate();
  return (
    <ThemeProvider>
      <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} minHeight={"100%"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Stack direction="column" gap="16px" width="55%">
            <Typography variant="h3">{title}</Typography>
            <Typography variant="body-large">{subtitle}</Typography>
            {hasRedirectToLoginBtn && (
              <ButtonWithLoadingState
                onClick={() => {
                  console.log("haha");
                  navigate("/login");
                }}
              >
                Back To Login
              </ButtonWithLoadingState>
            )}
          </Stack>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundImage: `url(${mfaBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "450px",
              maxHeight: "450px",
              aspectRatio: "1/1",
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <img
              style={{ width: "100%", height: "auto" }}
              src={errorImg}
              alt="error screen bg"
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
