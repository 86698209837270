import { isAxiosError } from "axios";

export const apiErrorHandler = (error) => {
  let errorData = {
    traceId: "",
    message: "",
    status: 200,
  };
  if (isAxiosError(error)) {
    errorData = {
      traceId: error.response?.headers["x-request-id"],
      message: error?.message || "",
      status: error.response?.status || 0,
    };
  } else {
    errorData = {
      traceId: error.headers["x-request-id"],
      message: error.data?.message,
      status: error.data?.status,
    };
  }
  return {
    message: errorData.message,
    traceId: errorData.traceId,
    status: errorData.status,
  };
};
