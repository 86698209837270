import { settingsImgErrorScreenBg } from "../../assets/images";
import { ErrorScreenLayout } from "./ErrorScreenLayout";

export const SentryErrorFallback = () => {
  return (
    <ErrorScreenLayout
      title={"Oops! Something went wrong"}
      subtitle={
        "We're sorry, but an unexpected error has occurred. Please bear with us as we work to fix the issue and restore normal functionality."
      }
      errorImg={settingsImgErrorScreenBg}
    />
  );
};
