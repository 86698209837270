import "./App.css";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
// import {
//   SignIn,
//   SignUp,
//   PasswordSetup,
//   ForgotPasswordPage,
// } from "./pages/auth";
// import { AgentPasswordSetup } from "./pages/agent";
import { ToasterComponent } from "./auth-components";
import { SecureRouter } from "./pages/auth/secureRouter";
import { Suspense, lazy } from "react";
import { CircularProgress } from "@mui/material";
import {
  CantScanQrCode,
  MfaAccountVerified,
  MfaLayout,
  MfaVerifyYourAccount,
  ScanQrCode,
} from "./pages/auth/mfa";
import { Sentry, SentryRoutes } from "./utils";
import {
  Error404Screen,
  SentryErrorFallback,
} from "./auth-components/ErrorScreens";
const SignIn = lazy(() => import("./pages/auth/signin/SignIn"));
const SignUp = lazy(() => import("./pages/auth/signup/SignUp"));
const PasswordSetup = lazy(() =>
  import("./pages/auth/passwordsetup/PasswordSetup")
);
const ForgotPasswordPage = lazy(() =>
  import("./pages/auth/forgotpassword/ForgotPasswordPage")
);
const AgentPasswordSetup = lazy(() =>
  import("./pages/agent/agentpasswordsetup/AgentPasswordSetup")
);
function App() {
  return (
    <div className="app">
      <Sentry.ErrorBoundary
        fallback={
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <SentryErrorFallback />
          </div>
        }
      >
        <Suspense
          fallback={
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                placeItems: "center",
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <ToasterComponent />
          <BrowserRouter>
            <SentryRoutes>
              <Route path="/" element={<Navigate to="login" />} />
              <Route
                path="login"
                element={
                  <SecureRouter>
                    <SignIn />
                  </SecureRouter>
                }
              />
              <Route
                path="signup"
                element={
                  <SecureRouter>
                    <SignUp />
                  </SecureRouter>
                }
              />
              <Route
                path="confirmpassword"
                element={<PasswordSetup action="confirmpassword" />}
              />
              <Route
                path="resetpassword"
                element={<PasswordSetup action="resetpassword" />}
              />
              <Route path="forgotpassword" element={<ForgotPasswordPage />} />
              <Route
                path="agentresetpassword"
                element={<AgentPasswordSetup action="agentresetpassword" />}
              />
              <Route
                path="agentconfirmpassword"
                element={<AgentPasswordSetup action="agentconfirmpassword" />}
              />
              <Route path="mfa" element={<MfaLayout />}>
                <Route index element={<Navigate to="scan-qrcode" />} />
                <Route path="scan-qrcode" element={<ScanQrCode />} />
                <Route path="cant-scan-qrcode" element={<CantScanQrCode />} />
                <Route
                  path="verify-account"
                  element={<MfaVerifyYourAccount />}
                />
                {/* <Route
                  path="account-verified"
                  element={<MfaAccountVerified />}
                /> */}
              </Route>
              <Route path="*" element={<Navigate to={"/404"} />} />
              <Route path="404" element={<Error404Screen />} />
            </SentryRoutes>
          </BrowserRouter>
        </Suspense>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
