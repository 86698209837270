function CheckBoxIcon(props) {
    return (<svg
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.998 8.99988L10.7527 14.9999L6.99805 11.4014"
            stroke={props.disabled === true ? props.disabledcolor : props.color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round" />
    </svg>
    );
}



export default CheckBoxIcon;