import {
  Box,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { TEXTFIELD_CONSTANTS } from "./textFieldConstants";
import { customStyles } from "./textFieldCustomStyles";

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Box
      sx={{
        width: "calc(100% - 32px)",
      }}
      className={
        " typography-font-size-class-extra-small typography-font-weight-class-regular"
      }
      style={{ fontFamily: "segoe_uiregular" }}
    >
      <input {...props}></input>
    </Box>
  );
});

const TextFieldNew = ({
  name,
  type,
  label,
  value,
  disabled,
  required,
  error,
  size,
  changeHandler,
  placeholder,
  onFocus,
  onBlur,
  helperText,
  autoCompleteString,
  endAdornment,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Stack
      width={"100%"}
      direction="column"
      gap="6px"
      className={
        " typography-font-size-class-extra-small typography-font-weight-class-regular"
      }
    >
      <Typography
        style={{ fontFamily: "segoe_uiregular" }}
        fontSize={"14px"}
        fontWeight={"400"}
        lineHeight={"10px"}
        letterSpacing={"-1%"}
        color={TEXTFIELD_CONSTANTS?.palette.color}
      >
        {label}
      </Typography>
      <TextField
        sx={{
          ...customStyles,
          "& input:-webkit-autofill": {
            backgroundColor: "#fff !important",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "#1C1E26 !important",
          },
        }}
        InputProps={{
          inputComponent: CustomInput,
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
        }}
        inputProps={{
          autoComplete: autoCompleteString || "off",
        }}
        fullWidth={true}
        name={name || ""}
        type={type || "text"}
        value={value}
        onChange={changeHandler}
        disabled={disabled || false}
        required={required || false}
        error={error || false}
        size={size}
        placeholder={placeholder || ""}
        onFocus={onFocus || null}
        onBlur={onBlur || null}
        helperText={helperText || ""}
        {...props}
      />
    </Stack>
  );
};

export { TextFieldNew };
