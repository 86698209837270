export const STATE_CONSTANTS = {
    COLOR: {
        DEFAULT: '#1C1E26', 
        HOVER: '#FFFFFF', 
        DISABLED: '#A5A5A6'
    },
    BACKGROUND_COLOR: {
        DEFAULT: '#FFFFFF', 
        HOVER: '#5563F5', 
        DISABLED: '#E0E0E0'
    }
}