function messageFilledIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 28L10.6667 22.668V22.6667H6.66667C5.19333 22.6667 4 21.4733 4 20V6.66667C4 5.19333 5.19333 4 6.66667 4H25.3333C26.8067 4 28 5.19333 28 6.66667V20C28 21.4733 26.8067 22.6667 25.3333 22.6667H21.3333L16 27.9987"
                fill={props.disabled === true ? props.disabledcolor : props.color} />
            <path
                d="M16.2383 13.762C16.3685 13.8922 16.3685 14.1032 16.2383 14.2334C16.1081 14.3636 15.8971 14.3636 15.7669 14.2334C15.6367 14.1032 15.6367 13.8922 15.7669 13.762C15.8971 13.6318 16.1081 13.6318 16.2383 13.762"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M21.5664 13.762C21.6966 13.8922 21.6966 14.1032 21.5664 14.2334C21.4363 14.3636 21.2252 14.3636 21.095 14.2334C20.9649 14.1032 20.9649 13.8922 21.095 13.762C21.2252 13.6318 21.4363 13.6318 21.5664 13.762"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M10.9024 13.762C11.0325 13.8922 11.0325 14.1032 10.9024 14.2334C10.7722 14.3636 10.5611 14.3636 10.431 14.2334C10.3008 14.1032 10.3008 13.8922 10.431 13.762C10.5611 13.6318 10.7722 13.6318 10.9024 13.762"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
}

export default messageFilledIcon;