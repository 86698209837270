function TaregetIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.9993 29.3346C23.3631 29.3346 29.3327 23.3651 29.3327 16.0013C29.3327 8.63751 23.3631 2.66797 15.9993 2.66797C8.63555 2.66797 2.66602 8.63751 2.66602 16.0013C2.66602 23.3651 8.63555 29.3346 15.9993 29.3346Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M16 24C20.4183 24 24 20.4183 24 16C24 11.5817 20.4183 8 16 8C11.5817 8 8 11.5817 8 16C8 20.4183 11.5817 24 16 24Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M16.0007 18.6654C17.4734 18.6654 18.6673 17.4715 18.6673 15.9987C18.6673 14.5259 17.4734 13.332 16.0007 13.332C14.5279 13.332 13.334 14.5259 13.334 15.9987C13.334 17.4715 14.5279 18.6654 16.0007 18.6654Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default TaregetIcon;