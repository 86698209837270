import { createContext, useState } from "react";

const AppContext = createContext();
const { Provider } = AppContext;

const AppProvider = ({ children }) => {
  const steps = [
    "Company settings",
    "Channel settings",
    "Add team members",
    "Add first customer",
  ];

  const [activeStep, setActiveStep] = useState(0);
  const [gettingStartedModalOpen, setGettingStartedModalOpen] = useState(false);
  const [skipped, setSkipped] = useState(new Set());

  const isStepOptional = (step) => {
    let optionalSteps = new Set([0, 1, 2]);
    return optionalSteps.has(step);
  };

  const isStepSkipped = (step) => {
    return skipped?.has(step);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) =>
      prevActiveStep === steps.length - 1 ? prevActiveStep : prevActiveStep + 1
    );
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Provider
      value={{
        activeStep,
        steps,
        setActiveStep,
        handleNext,
        handleBack,
        isStepOptional,
        isStepSkipped,
        handleSkip,
        handleReset,
        gettingStartedModalOpen,
        setGettingStartedModalOpen,
      }}
    >
      {children}
    </Provider>
  );
};

export { AppContext, AppProvider };
