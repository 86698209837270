function attributesIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.0033 28.0046H7.99667C6.52272 28.0046 5.32889 26.8108 5.32889 25.3368V6.66241C5.32889 5.18846 6.52272 3.99463 7.99667 3.99463H24.0033C25.4773 3.99463 26.6711 5.18846 26.6711 6.66241V25.3368C26.6711 26.8108 25.4773 28.0046 24.0033 28.0046Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.9983 10.664H21.3355"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.9983 21.3354H13.3322"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M22.1506 20.7056L19.5561 23.3L18.0008 21.7433"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.9983 15.9995H21.3355"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M3.32805 10.664H7.32972"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M3.32805 21.3354H7.06427"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M3.32805 15.9995H7.06427"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default attributesIcon;