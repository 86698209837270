import { Box, Stack, Typography } from "@mui/material";
import { copyIcon } from "../../../../assets/icons";
import { ThemeProvider } from "../../../../theme/ThemeProvider";
import { toast } from "react-hot-toast";

export const CodeCopyBox = ({ codeToBeCopied }) => {
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(codeToBeCopied)
      .then(() => {
        toast.success("Code copied!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.error("Failed to copy code!");
      });
  };
  return (
    <ThemeProvider>
      <Stack
        direction="row"
        p="12px 16px"
        alignItems={"center"}
        borderRadius={"8px"}
        bgcolor={"#E7EBF3"}
      >
        <Typography variant="body-medium" color="#767B85">
          {codeToBeCopied}
        </Typography>
        <Box onClick={copyToClipboard} ml="auto" sx={{ cursor: "pointer" }}>
          <img src={copyIcon} alt="copy icon" />
        </Box>
      </Stack>
    </ThemeProvider>
  );
};
