function customersIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="16.0089" cy="15.9963" r="12.6667" fill={props.disabled === true ? props.disabledcolor : props.color} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.6672 12.2018C19.6783 12.5136 19.6783 12.8272 19.6672 13.1391C19.5967 15.1089 17.9799 16.6696 16.0089 16.6704V16.6704C14.0378 16.6696 12.421 15.1089 12.3506 13.1391C12.3394 12.8272 12.3394 12.5136 12.3506 12.2017C12.421 10.2319 14.0378 8.67124 16.0089 8.67041V8.67041C17.9799 8.67124 19.5968 10.232 19.6672 12.2018V12.2018Z"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M10 22.0037C13.8025 20.226 18.1975 20.226 22 22.0037"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default customersIcon;