import { getCharLimitExceedErrorMsg } from "./getCharLimitExceedErrorMsg";
import { isEmailValid } from "./isEmailValid";

const getSignUpDataValidationObject = (signUpData) => {
  const {
    firstName,
    lastName,
    businessEmail,
    companyName,
    brandName,
    country,
    dataCenter,
  } = signUpData;

  const signUpDataValidationObject = {
    isSignUpDataFilled:
      firstName && lastName && businessEmail && companyName ? true : false,
    isSignUpDataValid: true,
    firstNameValidation: {
      isValid: firstName.length <= 50,
      errorMessage:
        firstName.length <= 50 ? "" : getCharLimitExceedErrorMsg(50),
    },
    lastNameValidation: {
      isValid: lastName.length <= 50,
      errorMessage: lastName.length <= 50 ? "" : getCharLimitExceedErrorMsg(50),
    },
    businessEmailValidation: {
      isValid: isEmailValid(businessEmail),
      errorMessage: isEmailValid(businessEmail) ? "" : "Invalid Email",
    },
    companyNameValidation: {
      isValid: companyName.length <= 150,
      errorMessage:
        companyName.length <= 150 ? "" : getCharLimitExceedErrorMsg(150),
    },
    // brandNameValidation: {
    //   isValid: brandName.length <= 50,
    //   errorMessage:
    //     brandName.length <= 50 ? "" : getCharLimitExceedErrorMsg(50),
    // },
    brandNameValidation: {
      isValid: true,
      errorMessage: "",
    },
    countryValidation: {
      isValid: country ? true : false,
      errorMessage: "",
    },
    dataCenterValidation: {
      isValid: dataCenter ? true : false,
      errorMessage: "",
    },
  };

  if (!firstName) {
    signUpDataValidationObject.firstNameValidation = {
      isValid: false,
      errorMessage: "",
    };
  }

  if (!lastName) {
    signUpDataValidationObject.lastNameValidation = {
      isValid: false,
      errorMessage: "",
    };
  }

  if (!businessEmail) {
    signUpDataValidationObject.businessEmailValidation = {
      isValid: false,
      errorMessage: "",
    };
  }

  if (!companyName) {
    signUpDataValidationObject.companyNameValidation = {
      isValid: false,
      errorMessage: "",
    };
  }

  // if (!brandName) {
  //   signUpDataValidationObject.brandNameValidation = {
  //     isValid: false,
  //     errorMessage: "",
  //   };
  // }

  const {
    firstNameValidation,
    lastNameValidation,
    businessEmailValidation,
    companyNameValidation,
    brandNameValidation,
    countryValidation,
    dataCenterValidation,
  } = signUpDataValidationObject;

  signUpDataValidationObject.isSignUpDataValid =
    firstNameValidation.isValid &&
    lastNameValidation.isValid &&
    businessEmailValidation.isValid &&
    companyNameValidation.isValid &&
    brandNameValidation.isValid &&
    countryValidation.isValid &&
    dataCenterValidation.isValid;

  return signUpDataValidationObject;
};

export { getSignUpDataValidationObject };
