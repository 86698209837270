import { axiosClient } from "../utils";


const loginService = (data) =>
  axiosClient.post(`/login`, {
    email: data.businessEmail,
    password: data.password,
  });

export { loginService };
