const defaultSizes = {
    sm: {
        height: '20px',
        width: '36px',
    },
    md: {
        height: '24px',
        width: '40px',
    },
    lg: {
        height: '28px',
        width: '48px',
    },
    xl: {
        height: '32px',
        width: '56px',
    },
};

export default defaultSizes;