export const TEXTFIELD_CONSTANTS = {
  height: { sm: 32, md: 36, lg: 42, xl: 56 },
  border_radius: "10px",
  palette: {
    outline: {
      DEFAULT: "#CCCCCC",
      SELECTED: "#5563F5",
      DISABLED: "#E3E3E3",
      ERROR: "#E3E3E3",
    },
    background: { ERROR: "#FFF3F2" },
    color: "#1C1E26",
    unfocus_color: "#767B85",
    bold_weight: 700,
    regular_weight: 400,
  },
};
