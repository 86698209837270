import { axiosClient } from "../utils";

const activateAccountService = (data) =>
  axiosClient.post(`/activate`, {
    userId: data.userId,
    token: data.token,
    password: data.password,
    ...(data.user ? { user: true } : {}),
  });

const activateAgentAccountService = (data) =>
  axiosClient.post(`/user/activate`, {
    userId: data.userId,
    token: data.token,
    password: data.password,
  });

export { activateAccountService, activateAgentAccountService };
