function monitorDisplayServerDatabaseDataIcon(props) {


    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.66113 17.4977H13.3278"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M17.5 11.6667V12.0833C17.5 13.464 16.3807 14.5833 15 14.5833H5C3.61929 14.5833 2.5 13.464 2.5 12.0833V5C2.5 3.61929 3.61929 2.5 5 2.5H7.5"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.875 14.5811L12.0833 17.4984"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M8.11947 14.5811L7.91113 17.4984"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M18.3333 7.5V6.66667C18.3333 5.74619 17.5871 5 16.6667 5H11.6667C10.7462 5 10 5.74619 10 6.66667V7.5"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <rect
                x="18.3389"
                y="9.16895"
                width="8.33333"
                height="7.5"
                rx="2"
                transform="rotate(-180 18.3389 9.16895)"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M15.832 7.08854L15.8278 7.09271L15.8236 7.08854L15.8278 7.08438L15.832 7.08854"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>
    );
}

export default monitorDisplayServerDatabaseDataIcon;