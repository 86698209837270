import { apiErrorHandler } from "../apiErrorHandler";
import { Sentry } from "./sentry";
export const sentryLogger = ({
  error,
  message,
  workflow,
  data,
  functionName,
  level = "error",
}) => {
  const newError = apiErrorHandler(error);
  Sentry.withScope((scope) => {
    scope.setLevel(level);
    scope.setTags({ workflow, functionName });
    scope.setTag("status", newError.status);
    scope.setTag("traceId", newError.traceId);
    scope.setTag("message", message ? message : newError.message);
    scope.setTag("workflow", workflow ? workflow : "auth");
    scope.setTransactionName(`${workflow}`);
    scope.setExtra("data", data);
    scope.setContext("data", data);
    Sentry.captureException(message ? message : newError.message);
  });
};
