import { Autocomplete, MenuItem, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { CustomTypography } from "../../library/base";
import { TEXTFIELD_CONSTANTS, TextFieldNew, customStyles } from "..";

const SELECTFIELD_CONSTANTS = {
  field: {
    height: { sm: 32, md: 40, lg: 48, xl: 56 },
    border_radius: "10px",
    palette: {
      outline: {
        DEFAULT: "#CCCCCC",
        SELECTED: "#5563F5",
        DISABLED: "#E3E3E3",
        ERROR: "#E3E3E3",
      },
      background: { ERROR: "#FFF3F2" },
      color: "#1C1E26",
      unfocus_color: "#767B85",
      bold_weight: 700,
      regular_weight: 400,
    },
  },
};

const SelectFieldNew = (props) => {
  // States
  const [selectedValue, setSelectedValue] = useState(props.value ?? "");

  // Handlers
  const handleChange = (e, newValue) => {
    setSelectedValue(newValue?.value);
    props.changeHandler(newValue);
  };

  return (
    <Stack
      width={"100%"}
      direction="column"
      gap="6px"
      className={
        " typography-font-size-class-extra-small typography-font-weight-class-regular"
      }
    >
      <Typography
        style={{ fontFamily: "segoe_uiregular" }}
        fontSize="14px"
        fontWeight={"400"}
        lineHeight={"10px"}
        letterSpacing={"-1%"}
        color={TEXTFIELD_CONSTANTS?.palette.color}
      >
        {props.label}
      </Typography>
      <Autocomplete
        id={`${props?.name}-autocomplete`}
        options={props?.options}
        getOptionLabel={(option) => option?.label}
        renderInput={(params) => (
          <TextFieldNew
            {...params}
            variant="outlined"
            error={props?.error || false}
            fullWidth
            sx={{
              ...customStyles,
              ".MuiInputBase-input.MuiOutlinedInput-input": {
                xs: {
                  marginTop: "0px",
                  fontSize: "12px",
                },
                md: {
                  marginTop: "-8px",
                  fontSize: "14px",
                },
                lg: {
                  marginTop: "-7px",
                  fontSize: "16px",
                },
                xl: {
                  marginTop: "0px",
                },
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <MenuItem {...props} key={option.key} value={option.value}>
            <CustomTypography
              size="EXTRA-SMALL"
              variant="REGULAR"
              content={option.label || ""}
            />
          </MenuItem>
        )}
        value={props?.options.find((option) => option.value === selectedValue)}
        onChange={handleChange}
        isOptionEqualToValue={(option, value) =>
          option["value"] === value["value"]
        }
        sx={{
          maxHeight: "200px",
          borderRadius: "SELECTFIELD_CONSTANTS?.field['border_radius']",
          backgroundColor: "#FFFFFF",
        }}
      />
    </Stack>
  );
};

export { SelectFieldNew };
