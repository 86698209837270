import { isEmailValid } from './isEmailValid';

const getLoginDataValidationObject = (loginData) => {
  const { businessEmail, password } = loginData;

  const loginDataValidationObject = {
    isLoginDataFilled: businessEmail && password ? true : false,
    isLoginDataValid: true,
    businessEmailValidation: {
      isValid: isEmailValid(businessEmail),
      errorMessage: isEmailValid(businessEmail) ? '' : 'Invalid Email',
    },
    passwordValidation: {
      isValid: true,
      errorMessage: '',
    },
  };

  if (!businessEmail) {
    loginDataValidationObject.businessEmailValidation = {
      isValid: false,
      errorMessage: '',
    };
  }

  if (!password) {
    loginDataValidationObject.passwordValidation = {
      isValid: false,
      errorMessage: '',
    };
  }

  const { businessEmailValidation, passwordValidation } =
    loginDataValidationObject;

  loginDataValidationObject.isLoginDataValid =
    businessEmailValidation.isValid && passwordValidation.isValid;

  return loginDataValidationObject;
};

export { getLoginDataValidationObject };
