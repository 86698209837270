function receiptCheckmarkLineIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.33881 8.75277L9.58881 10.0028L11.6721 7.91943"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M12.0779 12.4972H7.91119"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M16.6721 17.5V3.74794C16.6721 3.4329 16.4945 3.14479 16.213 3.00333L15.388 2.58873C15.1542 2.47127 14.8789 2.47038 14.6444 2.58634L13.334 3.23433L12.0499 2.58927C11.8155 2.47151 11.5393 2.47101 11.3045 2.58792L10.0055 3.2346L8.70651 2.58793C8.47166 2.47102 8.19547 2.47152 7.96105 2.58928L6.67696 3.23434L5.36656 2.58634C5.13207 2.47038 4.85674 2.47127 4.623 2.58873L3.798 3.00334C3.51649 3.14479 3.33882 3.4329 3.33881 3.74794V17.5"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M18.3279 17.4972H1.66119"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}

export default receiptCheckmarkLineIcon;