function qaIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="8" fill={props.disabled === true ? props.disabledcolor : props.color} />
            <path
                d="M12.4076 21.744C12.0342 21.904 11.6396 22.0267 11.2236 22.112C10.8076 22.208 10.3756 22.256 9.92756 22.256C9.07423 22.256 8.27956 22.1013 7.54356 21.792C6.81823 21.4827 6.1889 21.0613 5.65556 20.528C5.12223 19.984 4.7009 19.3493 4.39156 18.624C4.0929 17.888 3.94356 17.104 3.94356 16.272C3.94356 15.44 4.0929 14.6613 4.39156 13.936C4.7009 13.2 5.12223 12.5653 5.65556 12.032C6.1889 11.488 6.81823 11.0613 7.54356 10.752C8.27956 10.4427 9.07423 10.288 9.92756 10.288C10.7809 10.288 11.5702 10.4427 12.2956 10.752C13.0316 11.0613 13.6662 11.488 14.1996 12.032C14.7329 12.5653 15.1489 13.2 15.4476 13.936C15.7569 14.6613 15.9116 15.44 15.9116 16.272C15.9116 17.1573 15.7462 17.9787 15.4156 18.736C15.0849 19.4933 14.6209 20.144 14.0236 20.688L14.9036 22L13.3036 23.072L12.4076 21.744ZM9.97556 18.096L11.5756 17.008L12.8236 18.896C13.1116 18.5653 13.3356 18.1813 13.4956 17.744C13.6662 17.296 13.7516 16.8053 13.7516 16.272C13.7516 15.6853 13.6502 15.152 13.4476 14.672C13.2556 14.1813 12.9836 13.7653 12.6316 13.424C12.2902 13.0827 11.8849 12.816 11.4156 12.624C10.9569 12.432 10.4609 12.336 9.92756 12.336C9.39423 12.336 8.8929 12.432 8.42356 12.624C7.9649 12.816 7.55956 13.0827 7.20756 13.424C6.86623 13.7653 6.59423 14.1813 6.39156 14.672C6.19956 15.152 6.10356 15.6853 6.10356 16.272C6.10356 16.8587 6.19956 17.3973 6.39156 17.888C6.59423 18.368 6.86623 18.7787 7.20756 19.12C7.55956 19.4613 7.9649 19.728 8.42356 19.92C8.8929 20.112 9.39423 20.208 9.92756 20.208C10.1622 20.208 10.3862 20.1867 10.5996 20.144C10.8129 20.1013 11.0209 20.0427 11.2236 19.968L9.97556 18.096ZM20.6913 10.544H23.1553L27.4593 22H25.0753L24.1313 19.264H19.7153L18.7713 22H16.3873L20.6913 10.544ZM23.4273 17.28L22.4033 14.416L21.9873 13.024H21.8593L21.4433 14.416L20.4193 17.28H23.4273Z"
                fill={props.color === '#FFFFFF' ? props.varient.default.color : 'white'} />
        </svg>
    );
}
export default qaIcon;