export const getSecretFromQrDecodedStr = (qrDecodedStr) => {
  const parts = qrDecodedStr.split("?");
  if (parts.length < 2) {
    return null;
  }
  const params = parts[1].split("&");
  for (const param of params) {
    const keyValuePair = param.split("=");
    if (keyValuePair[0] === "secret" && keyValuePair.length === 2) {
      return keyValuePair[1];
    }
  }
  return null;
};
