import { Box } from "@mui/material";
import { ThemeProvider } from "../../../theme/ThemeProvider";
import mfaLottie from "../../../assets/lottie/mfa.json";
import mfaBg from "../../../assets/images/mfaBg.webp";
import Lottie from "lottie-react";
import { Outlet } from "react-router-dom";
export const MfaLayout = () => {
  return (
    <ThemeProvider>
      <Box display={"grid"} gridTemplateColumns={"1fr 1fr"} minHeight={"100%"}>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Outlet />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          sx={{
            backgroundImage: `url(${mfaBg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <Box
            sx={{
              maxWidth: "450px",
              maxHeight: "450px",
              aspectRatio: "1/1",
              width: "100%",
              height: "100%",
            }}
          >
            <MfaAnimationComponent />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

const MfaAnimationComponent = () => {
  return <Lottie animationData={mfaLottie} loop={true} />;
};
