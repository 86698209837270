import MuiLink from "@mui/material/Link";
import PropTypes from 'prop-types';
import defaultSizes from './constants/defaultSizes';
import { styled } from '@mui/material/styles'
import colorPalette from "../../common/colors";
function Link(props) {

    return (
        <MuiLink
            sx={{
                fontSize: defaultSizes,
                color: colorPalette.sematics.lightBg.info,
                underline: "always",
                '&.MuiTypography-root': {
                    fontWeight: '500'
                }
            }}
            href={props.href}
            style={{

            }}>

            {props.text}
        </MuiLink>
    );
}

Link.propTypes = {
    href: PropTypes.string.isRequired,
}

Link.defaultProps = {
    href: '',
}

export default Link;