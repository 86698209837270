import { useEffect, useState } from "react";
import { getAuthAccessToken } from "../../services/validateActiveAccount";
import jwtDecode from "jwt-decode";
import Lottie from "lottie-react";
import refreshLottie from "../../assets/lottie/loader.json";
import { Box } from "@mui/material";
import { sentryLogger } from "../../utils";
export const SecureRouter = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const cookies = document.cookie;

  const refreshTokens = cookies
    .split(";")
    .filter((item) => item.includes("fe_refresh_"));

  useEffect(() => {
    if (refreshTokens.length > 0) {
      setLoading(true);
      validateActiveAccount();
    }
  }, []);

  async function validateActiveAccount() {
    try {
      const response = await getAuthAccessToken();
      if (
        response?.data?.data &&
        response?.data?.data?.accessToken &&
        response?.data?.data?.refreshToken
      ) {
        let decoded;
        try {
          decoded = jwtDecode(response?.data?.data?.accessToken);
        } catch (e) {
          decoded = null;
        }
        if (decoded && decoded?.aud) {
          const redirectUrlWithParams = `${response?.data?.redirectUrl}/callback`;
          const newAud = `${decoded?.aud}`?.replace("-", "");

          const d = new Date();

          d.setTime(d.getTime() + 29 * 24 * 60 * 60 * 1000);
          const expires = d.toUTCString();

          document.cookie =
            `fe_refresh_${newAud}` +
            "=" +
            response?.data?.data?.refreshToken +
            ";expires=" +
            expires +
            ";path=/;domain=.deskday.ai";

          window.location.href = redirectUrlWithParams;
          return;
        }

        const cookiesWithoutToken = document.cookie
          .split(";")
          .filter((item) => !item.includes("fe_refresh_"));
        document.cookie = cookiesWithoutToken.join(";");
      } else {
        throw response;
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      sentryLogger({ e, functionName: "getAuthAccessToken" });
    }
  }

  return loading ? (
    <Box
      sx={{
        position: "absolute",
        top: "225px",
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: "#FFF",
        zIndex: 1000000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "520px", height: "520px" }}>
        <RefreshComponent />
      </Box>
    </Box>
  ) : (
    children
  );
};

const RefreshComponent = () => {
  return <Lottie animationData={refreshLottie} loop={true} />;
};
