import { Box, Stack, Typography, useTheme } from "@mui/material";
import { ButtonWithLoadingState } from "../../../../auth-components";
import { CodeCopyBox } from "./CodeCopyBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const CantScanQrCode = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { state } = useLocation();

  useEffect(() => {
    if (!state) {
      navigate("/404");
    }
  }, []);

  const recoveryCode = state?.recoveryCode;
  const loginData = state?.loginData;
  const userId = state?.userId;
  const mfaEnrolled = state?.mfaEnrolled;
  const backupCode = state?.backupCode;

  return (
    <Stack
      direction="column"
      gap="16px"
      width={{
        sm: "70%",
        md: "50%",
      }}
    >
      <Typography variant="h3">Can’t Scan QR ?</Typography>
      <Typography variant="body-large" color={theme.palette.lightBg.low}>
        Enter this code manually in your authenticator app.
      </Typography>
      <CodeCopyBox codeToBeCopied={backupCode} />
      <Box display="flex">
        <ButtonWithLoadingState
          onClick={() =>
            navigate("/mfa/verify-account", {
              state: { recoveryCode, loginData, userId, mfaEnrolled },
            })
          }
        >
          Continue
        </ButtonWithLoadingState>
      </Box>
    </Stack>
  );
};
