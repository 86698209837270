import { Box, CircularProgress, useTheme } from "@mui/material";
import "./ButtonWithLoadingState.css";
import { ThemeProvider } from "../../theme/ThemeProvider";

const ButtonWithLoadingState = ({
  children,
  isLoading = false,
  disabled = false,
  type = "submit",
  variant = "primary",
  onClick,
}) => {
  const theme = useTheme();
  return (
    <ThemeProvider>
      <button
        onClick={onClick}
        style={{
          ...theme.typography["button-small"],
          color:
            variant === "secondary"
              ? "var(--clr-font-primary)"
              : "var(--clr-white)",
          backgroundColor:
            variant === "secondary" ? "var(--clr-white)" : "var(--clr-primary)",
          border: `1px solid ${
            variant === "secondary"
              ? "var(--clr-font-primary)"
              : "var(--clr-primary)"
          }`,
        }}
        className={`btn-withLoadingState ${
          disabled || isLoading ? "btn-disabled" : "btn-active"
        }`}
        disabled={disabled || isLoading}
        type={type}
        // onClick={(e) => onClick(e)}
      >
        {isLoading ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CircularProgress size="18px" sx={{ color: "var(--clr-white)" }} />
          </Box>
        ) : (
          children
        )}
      </button>
    </ThemeProvider>
  );
};

export { ButtonWithLoadingState };
