import { TEXTFIELD_CONSTANTS } from "./textFieldConstants";

export const customStyles = {
  "& .MuiInputLabel-root": {
    display: "none",
  },
  "& .MuiInputBase-root": {
    height: TEXTFIELD_CONSTANTS["height"],
  },
  "& .MuiOutlinedInput-root": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["DEFAULT"],
      borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    color: TEXTFIELD_CONSTANTS?.palette["color"],
    "& > fieldset": {
      borderColor: TEXTFIELD_CONSTANTS?.palette?.outline["SELECTED"],
    },
  },
};
