function GeneralIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.99634 14.0007V6.99783C3.99634 5.34014 5.3399 3.99658 6.99759 3.99658H19.0026C20.6603 3.99658 22.0038 5.34014 22.0038 6.99783V19.0028C22.0038 20.6605 20.6603 22.0041 19.0026 22.0041H13.0001"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.6865 12.2819L13.6683 14.2637L16.5015 11.4966C16.5385 11.4606 16.6006 11.4726 16.6226 11.5186C17.3559 13.0863 17.0127 15.0171 15.7172 16.3116C14.4627 17.5661 12.6789 17.8963 11.1593 17.302L6.98657 21.4748C6.30228 22.158 5.19282 22.158 4.50953 21.4748C3.82625 20.7905 3.82625 19.681 4.50953 18.9977L8.64926 14.858C8.05401 13.3394 8.38515 11.5556 9.63967 10.3001C10.9322 9.00756 12.859 8.72644 14.4277 9.39772C14.4747 9.41873 14.4867 9.48276 14.4507 9.51977L11.6865 12.2819Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>


    );
}


export default GeneralIcon;