import { getDataCentersService } from "../services";

const getDataCenters = async () => {
  try {
    const response = await getDataCentersService();
    if (
      response?.data?.statusCode === 200 &&
      response?.data?.success === true &&
      response?.data?.data &&
      Array.isArray(response?.data?.data)
    ) {
      return (response?.data?.data).map((dataItem) => ({
        key: dataItem.id,
        value: dataItem.center_name,
        label: dataItem.center_name,
      }));
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export { getDataCenters };
