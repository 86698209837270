function uploadFileIcon(props) {
    return (
        <svg
            width="100%"
            height="95%"
            viewBox="0 0 23 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.9274 14.52C14.3102 14.9067 14.9307 14.9067 15.3135 14.52C15.7096 14.1333 15.7096 13.5067 15.3267 13.12L11.538 9.28C11.4455 9.18667 11.3399 9.12 11.2211 9.06667C11.1023 9.01333 10.9835 8.98667 10.8515 8.98667C10.7195 8.98667 10.5875 9.01333 10.4686 9.06667C10.3498 9.12 10.2442 9.18667 10.1518 9.28L6.37624 13.12C5.9934 13.5067 5.9934 14.1333 6.37624 14.52C6.75908 14.9067 7.37954 14.9067 7.76238 14.52L9.86139 12.3867V18.8267C9.86139 19.3733 10.297 19.8133 10.8515 19.8133C11.3927 19.8133 11.8284 19.3733 11.8284 18.8267V12.3867L13.9274 14.52ZM21.1042 9.36748C21.4145 9.3639 21.7524 9.36 22.0594 9.36C22.3894 9.36 22.6667 9.62667 22.6667 9.96V20.68C22.6667 23.9867 20 26.6667 16.7261 26.6667H6.23102C2.78548 26.6667 0 23.8533 0 20.3867V6.01333C0 2.70667 2.65347 0 5.9538 0H13.0033C13.3333 0 13.6106 0.28 13.6106 0.613333V4.90667C13.6106 7.34667 15.5908 9.34667 18.0198 9.36C18.5751 9.36 19.068 9.36419 19.5011 9.36788C19.8402 9.37076 20.1425 9.37333 20.4092 9.37333C20.5972 9.37333 20.8407 9.37052 21.1042 9.36748ZM21.4729 7.42133C20.3877 7.42533 19.1098 7.42133 18.1897 7.412C16.7296 7.412 15.527 6.19733 15.527 4.72267V1.208C15.527 0.633333 16.2161 0.348 16.6108 0.762667C17.628 1.82986 19.1853 3.46566 20.5011 4.84775C21.0331 5.40653 21.5256 5.92384 21.927 6.34533C22.3112 6.74933 22.0286 7.42 21.4729 7.42133Z"
                fill={props.disabled === true ? props.disabledcolor : props.color} />
        </svg>

    );
}
export default uploadFileIcon;