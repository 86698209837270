import { ToastBar, Toaster, toast } from "react-hot-toast";
import { toasterCloseIcon } from "../../assets/icons";

const ToasterComponent = () => {
    return <Toaster
    position="top-right"
    toastOptions={{
      style: {
        backgroundColor: "#10172E",
        borderRadius: "100px",
        minWidth: "300px",
        maxWidth: "500px",
        color: "#FFF",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between !important",
        minHeight: "48px",
      },
      success: {
        duration: 3000,
        style: {
          border: "1px solid #198038",
        },
      },
      error: {
        duration: 3000,
        style: {
          border: `1px solid #DA1E28`,
        },
      },
      custom: {
        duration: 3000,

        style: {
          border: `1px solid #F1C21B`,
        },
      },
    }}
    children={(t) => (
      <ToastBar toast={t}>
        {({ icon, message }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              borderRadius: "24px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div aria-label="Close Notification">{icon}</div>
              {message}
            </div>
            
           <div style={{
            cursor: "pointer",
            height: "24px",
            width: "24px",
           }}
           onClick={() => toast.dismiss(t.id)}
           >
           <img
                  src={toasterCloseIcon}
                  alt='toaster close icon'
                />
           </div>
            
            {/* <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => toast.dismiss(t.id)}
              htmlColor="#A5A5A6"
            /> */}
          </div>
        )}
      </ToastBar>
    )}
  ></Toaster>
}

export {ToasterComponent}