function notesIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.0004 14.7805H17.0021"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.0029 3.49658H4.99708C3.89205 3.49658 2.99625 4.39239 2.99625 5.49742V18.5028C2.99625 19.6079 3.89205 20.5037 4.99708 20.5037H19.0029C20.1079 20.5037 21.0037 19.6079 21.0037 18.5028V5.49742C21.0037 4.39239 20.1079 3.49658 19.0029 3.49658Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M6.99792 14.5063L7.91731 15.3347L9.76908 13.668"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M13.0004 9.77804H17.0021"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M6.99792 9.50388L7.91731 10.3322L9.76808 8.66553"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default notesIcon;