export const getResponsiveIconButtonSize = ()=> {
    let width = window.screen.width;
    try{
        if (width <= 1024) {
            return 'sm';
        }
        if (width > 1024 && width <= 1440) {
            return 'md';
        }
        if (width > 1440 && width <= 1604) {
            return 'lg';
        }
        if (width > 1604) {
            return 'xl';
        }
    }catch(err) {
        console.log('[ERROR] Getting Responsive Icon Button Sizes');
        console.log(err);
    }
}