import "./PasswordRules.css";

const PasswordRules = ({ action }) => {
  return (
    <ul
      className="passwordrules"
      style={{ fontFamily: "segoe_uiregular", fontSize: "14px" }}
    >
      <li className="passwordrules-item">
        <div className="passwordrules-bullet"></div>Minimum of 8 characters.
      </li>
      <li className="passwordrules-item">
        <div className="passwordrules-bullet"></div>Maximum of 32 characters.
      </li>
      <li className="passwordrules-item">
        <div className="passwordrules-bullet"></div>4 of 4 : uppercase,
        lowercase, number, special character.
      </li>
      <li className="passwordrules-item">
        <div className="passwordrules-bullet"></div>Avoid 3 recurring
        characters.
      </li>
      {(action === "resetpassword" || action === "agentresetpassword") && (
        <li className="passwordrules-item">
          <div className="passwordrules-bullet"></div>Password must not be the
          same as the last three passwords used.
        </li>
      )}
    </ul>
  );
};

export { PasswordRules };
