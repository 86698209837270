import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import { v4 } from "uuid";

// Legacy Imports
import Box from '@mui/material/Box';

// HOC Imports
import CustomIcon from "../../icon-library";

// utilz
import { getResponsiveIconButtonSize } from "../../../layouts/helpers/icon-button-size";

// Constants Imports
import { STATE_CONSTANTS } from "./constants";

const CustomPaginationControlButton = (props)=> {
    // State
    const [state, setState] = useState({
        color: STATE_CONSTANTS.COLOR['DEFAULT'],
        key: '',
        disabled: false
    })
    // Effects
    useEffect(()=> {
        try{
            setState({
                ...state,
                key: v4(),
                disabled: props?.disabled
            })
        }catch(err) {
            console.log('[ERROR] Mounting Pagination control');
            console.log(err)
        }
    }, [props])

    // Handler
    const handleMouseEnter = ()=> {
        if(state?.disabled === false) {
            setState({...state, color: STATE_CONSTANTS.COLOR['HOVER']})
        }        
    };
    const handleMouseLeave = ()=> {
        if(state?.disabled === false) {
            setState({...state, color: STATE_CONSTANTS.COLOR['DEFAULT']})
        }        
    };
    const handleClick = ()=> {
        try{
            if(state?.disabled === false) {
                props?.handle();
            }
        }catch(err) {
            console.log('[ERROR] Click Handler of Pagination control');
            console.log(err)
        }
    }
    

    // Renderer
    return(
        <React.Fragment>
            <Box
                key = {state?.key}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: state?.disabled === true? 
                        STATE_CONSTANTS?.BACKGROUND_COLOR['DISABLED'] 
                        :
                        STATE_CONSTANTS?.BACKGROUND_COLOR['DEFAULT'],
                    borderRadius: '50%',
                    border: '1px solid #EDEDED',
                    padding: '16px',
                    width: '40px',
                    height: '40px',
                    '&:hover': {
                        backgroundColor: state?.disabled === true?
                            STATE_CONSTANTS?.BACKGROUND_COLOR['DISABLED']
                            :
                            STATE_CONSTANTS?.BACKGROUND_COLOR['HOVER'],
                        borderColor: state?.disabled === true?
                            '#EDEDED'
                            :
                            '#5563F5',
                        cursor: state?.disabled === true? 'default':'pointer',
                    }
                }}
                onMouseEnter = {handleMouseEnter} 
                onMouseLeave = {handleMouseLeave}
                onClick = {handleClick}
            >
                <CustomIcon
                    size={`icon_${getResponsiveIconButtonSize()}`}
                    icon={props?.isIncrement === true? 'arrow_right': 'arrow_left'}
                    color = {state['color']}
                />
            </Box>
        </React.Fragment>
    )
}

CustomPaginationControlButton.propTypes = {
    isIncrement: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    handle: PropTypes.func.isRequired
}
CustomPaginationControlButton.defaultProps = {
    isIncrement: false,
    disabled: false,
    handle: ()=> {}
}

export default CustomPaginationControlButton;