function deleteIcon(props) {


    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.543 21.0038H8.45722C7.28103 21.0038 6.30313 20.0982 6.21292 18.9255L5.24731 6.37268H18.7529L17.7873 18.9255C17.6971 20.0982 16.7192 21.0038 15.543 21.0038V21.0038Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M20.0035 6.37264H3.99683"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.1865 2.99622H14.8138C15.4354 2.99622 15.9393 3.50011 15.9393 4.12168V6.37262H8.06104V4.12168C8.06104 3.50011 8.56492 2.99622 9.1865 2.99622Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M13.9697 10.8745V16.5019"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M10.0305 10.8745V16.5019"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}

export default deleteIcon;