import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';

// Legacy Imports
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

// HOC
import CustomIcon from "../../icon-library";

// Constants
import { TEXTFIELD_CONSTANTS } from "./constants";

// Customization 
const customStyles = {
    "& .MuiOutlinedInput-root": {   
        color: TEXTFIELD_CONSTANTS?.palette['unfocus_color'],         
        "& > fieldset": { 
            borderColor: TEXTFIELD_CONSTANTS?.palette?.outline['DEFAULT'],
            borderRadius: TEXTFIELD_CONSTANTS?.border_radius,
        },
    },        
    "& .MuiOutlinedInput-root.Mui-focused": {
        color: TEXTFIELD_CONSTANTS?.palette['color'],
        "& > fieldset": {borderColor: TEXTFIELD_CONSTANTS?.palette?.outline['SELECTED'],}
    },
    "& label": {
        color: TEXTFIELD_CONSTANTS?.palette['color'],
        fontWeight: TEXTFIELD_CONSTANTS?.palette['bold_weight'],
        "&.Mui-focused": {color: TEXTFIELD_CONSTANTS?.palette?.outline['SELECTED']}
    }
}

const SizableTextField = (props)=> {
    // State
    const [value, setValue] = useState('');

    // Effects
    useEffect(()=> {setValue(props.value)}, [props]);

    // Event Handlers
    const handleChange = (event)=> {
        props?.changeHandler({field: props.name, value: event.target.value});
        setValue(event.target.value)
    }  

    return (
        <TextField
            name = {props.name || ''}
            label = {props.label || ''}
            type = {props.type || 'text'}
            value = {value}
            size = {props?.size}
            fullWidth = {true}
            disabled = {props.disabled || false}
            required = {props.required || false}
            error = {props.error || false}
            helperText = {props.helperText || ''}
            placeholder = {props.placeholder || ''}
            sx={customStyles}
            InputProps = {
                props?.adornment === true?
                    {
                        startAdornment: 
                            <InputAdornment position={props?.adornmentPosition}>
                                <CustomIcon
                                    icon = {props?.adornmentValue}
                                    size = {'sm'}
                                    color = {'#1C1E26'}                                                                          
                                />
                            </InputAdornment>
                    }
                    :
                    {}
            }
            onChange = {handleChange}
        />
    )
}

SizableTextField.propTypes = {
    name : PropTypes.string.isRequired,
    type : PropTypes.string.isRequired,
    label : PropTypes.string.isRequired,
    value : PropTypes.string.isRequired,
    size : PropTypes.string.isRequired,
    adornment: PropTypes.bool.isRequired,
    adornmentPosition: PropTypes.string.isRequired,
    adornmentValue: PropTypes.string.isRequired,
    disabled : PropTypes.bool.isRequired,
    required : PropTypes.bool.isRequired,
    error : PropTypes.bool.isRequired,
    helperText : PropTypes.string.isRequired,
    placeholder : PropTypes.string.isRequired,
    size : PropTypes.string.isRequired,
    changeHandler : PropTypes.func.isRequired
}

SizableTextField.defaultProps = {
    name : '',
    type : '',
    label : '',
    value : '',
    size : '',
    adornment: false,
    adornmentPosition: '',
    adornmentValue: '',
    disabled : false,
    required : false,
    error : false,
    helperText : '',
    placeholder : '',
    size : 'medium',
    changeHandler : ()=>{}
}

export default SizableTextField;