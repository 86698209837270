import toast from "react-hot-toast";

export const showApiErrorToaster = ({ error, fallBackErrMsg }) => {
  if (typeof error?.data?.message === "string") {
    toast.error(
      error?.data?.message ||
        fallBackErrMsg ||
        "Failed! Please try again later",
      { duration: error?.data?.statusCode === 401 ? 8000 : 3000 }
    );
  } else {
    toast.error(
      error?.response?.data?.message ||
        fallBackErrMsg ||
        "Failed! Please try again later"
    );
  }
};
