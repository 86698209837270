import { error404ScreenBg } from "../../assets/images";
import { ErrorScreenLayout } from "./ErrorScreenLayout";

export const Error404Screen = () => {
  return (
    <ErrorScreenLayout
      title={"Oops! Something went wrong"}
      subtitle={
        "Sorry, we couldn’t find the page you are looking for. It might have been removed or doesn’t exist"
      }
      errorImg={error404ScreenBg}
    />
  );
};
