import icons from './config/iconConfig';
import { defaultSizes, buttonDefaultSize } from './constants/defaultSizes';
import React from 'react';
import PropTypes from 'prop-types';
import colorPalette from '../common/colors';
import { Box } from '@mui/material';
import { useContext } from 'react';
import IconContext from './config/iconContext';
function CustomIcon(props) {
    const Icon = icons[props.icon];
    const newProps = { ...props, ...Icon?.props ?? {}, disabledcolor: colorPalette.font.darkBg.tertiary, color: props.color, varient: props.varientcolorpalette };
    const isButton = useContext(IconContext) ?? false;
    const defaultSize = isButton ? buttonDefaultSize : defaultSizes;
    const IconName = Icon?.icon ?? 'div';
    return (

        <Box
            onClick={props.onClick}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
                aspectRatio: '1',
                width: {
                    xs: defaultSize[props.size] ?? defaultSize.sm,
                    sm: defaultSize[props.size] ?? defaultSize.sm,
                    md: defaultSize[props.size] ?? defaultSize.md,
                    lg: defaultSize[props.size] ?? defaultSize.lg,
                    xl: defaultSize[props.size] ?? defaultSize.xl,
                },
                height: {
                    xs: defaultSize[props.size] ?? defaultSize.sm,
                    sm: defaultSize[props.size] ?? defaultSize.sm,
                    md: defaultSize[props.size] ?? defaultSize.md,
                    lg: defaultSize[props.size] ?? defaultSize.lg,
                    xl: defaultSize[props.size] ?? defaultSize.xl,
                },
                ...props.sx,
            }}><IconName {...newProps} /></Box>
    );
}



CustomIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    varientcolorpalette: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
    size: PropTypes.string,
    sx: PropTypes.object,
    onClick: PropTypes.func,
}

CustomIcon.defaultProps = {
    icon: 'plus',
    color: colorPalette.color.primary.primary,
    disabled: false,
    size: '',

}

export default CustomIcon;
