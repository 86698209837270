import MUiIconButton from "@mui/material/IconButton";
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles'
import Icon from "../../icon-library";
import defaultSizes from "./constants/defaultSizes";
import defaultColors from "./constants/defaultColors";
import { useState, useEffect } from "react";
import IconContext from "../../icon-library/config/iconContext";
function IconButton(props) {
    const colorPalette = defaultColors[props.varient] ?? defaultColors.primary;
    const size = defaultSizes[props.size];

    const [color, setColor] = useState(colorPalette.default.color);

    useEffect(() => {
        if (props.isSelected === true) {
            setColor(colorPalette.hover.color)
        }
    }, []);

    return (
        <MUiIconButton
            disableRipple
            disabled={props.disabled}
            onClick={props.onClick}
            onMouseEnter={() => {

                setColor(colorPalette.hover.color)
            }} onMouseLeave={() => {
                if (!props.isSelected) {
                    setColor(colorPalette.default.color)
                }
            }}
            sx={{
                width: {
                    xs: defaultSizes.sm,
                    sm: defaultSizes.sm,
                    md: defaultSizes.md,
                    lg: defaultSizes.lg,
                    xl: defaultSizes.xl,
                },
                height: {
                    xs: defaultSizes.sm,
                    sm: defaultSizes.sm,
                    md: defaultSizes.md,
                    lg: defaultSizes.lg,
                    xl: defaultSizes.xl,
                },
                backgroundColor: props.isSelected === true ? colorPalette.hover.backgroundColor : colorPalette.default.backgroundColor,
                borderColor: colorPalette.default.borderColor ?? 'transparent',
                outlineColor: colorPalette.default.outlineColor ?? 'transparent',
                color: props.isSelected === true ? colorPalette.hover.color : colorPalette.default.color,
                borderRadius: '8px',
                aspectRatio: '1',
                padding: '0px',
                borderWidth: '1.2px',
                borderStyle: 'solid',
                outlineWidth: '1px',
                outlineOffset: '-3px',
                outlineStyle: 'solid',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&.Mui-focusVisible': {
                    backgroundColor: props.isSelected === true ? colorPalette.hover.color : colorPalette.focused.backgroundColor,
                    borderColor: colorPalette.focused.borderColor ?? 'transparent',
                    outlineColor: colorPalette.focused.outlineColor ?? 'transparent',
                    color: props.isSelected === true ? colorPalette.hover.color : colorPalette.focused.color
                },
                '&:hover': {
                    backgroundColor: colorPalette.hover.backgroundColor,
                    color: colorPalette.hover.color
                },
                '&:active': {
                    backgroundColor: colorPalette.pressed.backgroundColor,
                    color: colorPalette.pressed.color
                },
                '&.Mui-disabled': {
                    backgroundColor: colorPalette.disabled.backgroundColor,
                    borderColor: colorPalette.disabled.borderColor ?? 'transparent',
                    outlineColor: colorPalette.disabled.outlineColor ?? 'transparent',
                    color: colorPalette.disabled.color
                }
            }}

        >

            <IconContext.Provider value={true}>
                <Icon icon={props.icon} color={color} disabled={props.disabled} varientcolorpalette={colorPalette} />
            </IconContext.Provider>
        </MUiIconButton>
    );
}


IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    disabled: PropTypes.bool.isRequired,
    varient: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isSelected: PropTypes.bool,
}

IconButton.defaultProps = {
    icon: "plus",
    disabled: false,
    varient: 'primary',
    isSelected: false,
    onClick: () => { }
}

export default IconButton;