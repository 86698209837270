const isPasswordValid = (password) => {
  if (password === '') {
    return true;
  }
  if (password.length < 8) {
    return false;
  }
  if (password.length > 32) {
    return false;
  }
  const passwordRegex =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^a-zA-Z0-9])(?!.*(.)\1{2}).{8,}$/;

  return passwordRegex.test(password);
};

export { isPasswordValid };
