function orederedListIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11 4H20"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.15 9H20"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M5.25 9V4L4 5.25"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11 14H20"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M11.15 19H20"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M4 15C4 14.4477 4.44772 14 5 14H5.61448C6.1433 14 6.61278 14.3384 6.78 14.8401V14.8401C6.91647 15.2495 6.82812 15.7005 6.54727 16.0282L4 19H7"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M4 9H6.5"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default orederedListIcon;