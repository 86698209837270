function homeFilledIcon(props) {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.8503 8.30275L13.8478 3.63381C12.7639 2.79068 11.2461 2.79068 10.1622 3.63381L4.15973 8.30275C3.42903 8.87117 3.00153 9.74499 3.00125 10.6707V18.0077C3.00125 19.6653 4.34496 21.009 6.0025 21.009H18.0075C19.665 21.009 21.0088 19.6653 21.0088 18.0077V10.6707C21.0085 9.74499 20.581 8.87117 19.8503 8.30275Z"
                fill={props.disabled === true ? props.disabledcolor : props.color} />
            <path
                d="M9.50396 16.0066H14.506"
                stroke={props.color === '#FFFFFF' ? props.varient.default.color : 'white'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default homeFilledIcon;