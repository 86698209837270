const colors = {
    color: {
        primary: {
            primary: '#5563F5',
            primaryPressed: '#1021CB',
            primaryHover: '#3547FD',
            secondary: '#57E4F8',
            tertiary: '#171D41',
            stock: '#1C1E26'
        },
        secondary: {
            primaryTintPrimary: '#8791F8',
            primaryAccentTintPrimary: '#89ECFA',
            secondary: '#F5E755',
            secondaryShade: '#E5D41E',
            secondaryTint: '#FCF8CF',
            primaryTintSecondary: '#B7BDFB',
            primaryTintTertiary: '#E7E9FE',
            primaryAccentTintTertiary: '#EBFCFE',
            primaryAccentTintSecondary: '#BAF4FC',
        }
    },
    font: {
        lightBg: {
            primary: '#1C1E26',
            secondary: '#383940',
            tertiary: '#767B85',
        },
        darkBg: {
            primary: '#FFFFFF',
            secondary: '#E0E0E0',
            tertiary: '#A5A5A6',
        }
    },
    sematics: {
        lightBg: {
            success: '#198038',
            successTint: '#E0FFE0',
            error: '#DA1E28',
            errorTint: '#FFF3F2',
            warningMajor: '#FF832B',
            warningMajorTint: '#FFE6D5',
            warningMinor: '#F1C21B',
            warningMinorTint: '#F8F1CF',
            info: '#008DCA',
            infoTint: '#E9FCFF',
        }
    },
    neutrals: {
        lightBg: {
            primary: '#FFFFFF',
            secondary: '#F6F6F5',
            tertiary: '#EDEDED',
            quartary: '#E3E3E3',
            quinary: '#CCCCCC',
        }
    }

}


export default colors;