import { axiosClient } from '../utils';

const signUpService = (data) =>
  axiosClient.post(`/signup`, {
    companyName: data.companyName,
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    data_center: data.data_center,
    brand_name: data.brand_name,
    country: data.country,
  });

export { signUpService };
