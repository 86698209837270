function downloadIcon(props) {


    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10 14.1667V2.5"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M16.6667 17.4997H3.33337"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M14.1667 10L9.99919 14.1675L5.83252 10"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}

export default downloadIcon;