function homeIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.60091 13.75V9.33332C2.60092 8.06757 3.17629 6.87044 4.16467 6.07973L7.49801 3.41306C9.01976 2.19565 11.1821 2.19565 12.7038 3.41306L16.0372 6.07973C17.0255 6.87045 17.6009 8.06757 17.6009 9.33332V13.75C17.6009 15.8211 15.922 17.5 13.8509 17.5H6.35091C4.27984 17.5 2.60091 15.8211 2.60091 13.75Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>


    );
}

export default homeIcon;