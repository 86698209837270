import { isPasswordValid } from './isPasswordValid';

const getConfirmPasswordDataValidationObject = (confirmPasswordData) => {
  const { password, confirmPassword } = confirmPasswordData;

  const confirmPasswordDataValidationObject = {
    isConfirmPasswordDataFilled: password && confirmPassword ? true : false,
    isConfirmPasswordDataValid: true,
    passwordValidation: {
      isValid: isPasswordValid(password),
      errorMessage: isPasswordValid(password)
        ? ''
        : "Didn't match password criteria!",
    },
    confirmPasswordValidation: {
      isValid: true,
      errorMessage: '',
    },
    isPasswordEqualsConfirmPassword: true,
  };

  if (!password) {
    confirmPasswordDataValidationObject.passwordValidation = {
      isValid: false,
      errorMessage: '',
    };
  }

  if (!confirmPassword) {
    confirmPasswordDataValidationObject.confirmPasswordValidation = {
      isValid: false,
      errorMessage: '',
    };
  }

  if (
    password &&
    confirmPassword &&
    confirmPasswordDataValidationObject.passwordValidation.isValid &&
    password !== confirmPassword
  ) {
    confirmPasswordDataValidationObject.isPasswordEqualsConfirmPassword = false;
  }

  if (!confirmPasswordDataValidationObject.isPasswordEqualsConfirmPassword) {
    confirmPasswordDataValidationObject.passwordValidation = {
      isValid: false,
      errorMessage: '',
    };
    confirmPasswordDataValidationObject.confirmPasswordValidation = {
      isValid: false,
      errorMessage: '',
    };
  }

  const { passwordValidation, confirmPasswordValidation } =
    confirmPasswordDataValidationObject;

  confirmPasswordDataValidationObject.isConfirmPasswordDataValid =
    passwordValidation.isValid && confirmPasswordValidation.isValid;

  // confirmPasswordDataValidationObject.isConfirmPasswordDataValid =
  //   passwordValidation.isValid &&
  //   confirmPasswordValidation.isValid &&
  //   isPasswordEqualsConfirmPassword;

  return confirmPasswordDataValidationObject;
};

export { getConfirmPasswordDataValidationObject };
