import {
  Box,
  CircularProgress,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { ButtonWithLoadingState } from "../../../../auth-components";
import { useLocation, useNavigate } from "react-router-dom";
import { enrollAuthApp } from "../../../../services";
import { useEffect, useRef, useState } from "react";
import jsQR from "jsqr";
import { getSecretFromQrDecodedStr } from "../helpers";
import { sentryLogger, showApiErrorToaster } from "../../../../utils";

export const ScanQrCode = () => {
  // Theme
  const theme = useTheme();

  // Router related
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      navigate("/404");
    }
  }, []);
  const userId = state?.userId;
  const loginData = state?.loginData;
  const qrCodeFromLoginApi = state?.qrCode;
  const recoveryCode = state?.recoveryCode;
  const mfaEnrolled = state?.mfaEnrolled;

  // Ref
  const imageRef = useRef(null);
  const canvasRef = useRef(null);

  // State
  const [imgBase64Str, setImgBase64Str] = useState(qrCodeFromLoginApi);

  // Handlers
  let backupCode = "";
  const decodeQRCode = () => {
    const ctx = canvasRef.current.getContext("2d");
    canvasRef.current.width = imageRef.current.width;
    canvasRef.current.height = imageRef.current.height;
    ctx.drawImage(imageRef.current, 0, 0);

    const imageData = ctx.getImageData(
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    );
    const code = jsQR(imageData.data, imageData.width, imageData.height);
    backupCode = getSecretFromQrDecodedStr(code?.data);
    return backupCode;
  };

  useEffect(() => {
    if (state) {
      if (!qrCodeFromLoginApi) {
        (async () => {
          try {
            const response = await enrollAuthApp({ userId });
            if (
              response?.data?.statusCode === 200 &&
              response?.data?.success === true
            ) {
              setImgBase64Str(response?.data?.data?.qrCode);
            } else {
              throw response;
            }
          } catch (error) {
            showApiErrorToaster({ error });
            sentryLogger({
              error,
              functionName: "enrollAuthApp",
            });
          }
        })();
      }
    }
  }, [userId, qrCodeFromLoginApi]);

  return (
    <Stack direction="column" gap="16px" maxWidth={"80%"}>
      <Typography
        variant="h3"
        width={"80%"}
        lineHeight={{
          xl: "1.1",
        }}
      >
        Setup Multi-Factor Authentication
      </Typography>
      <Typography
        variant="body-large"
        width={"60%"}
        color={theme.palette.lightBg.low}
      >
        Use your Authenticator app to scan the QR code below.
      </Typography>
      <Box
        sx={{
          minWidth: "228px",
          width: "60%",
          aspectRatio: "1",
          border: `1px solid ${theme.palette.darkBg.medium}`,
          borderRadius: "2px",
          display: "grid",
          placeItems: "center",
        }}
      >
        {imgBase64Str ? (
          <>
            <img
              ref={imageRef}
              src={imgBase64Str}
              alt="QR"
              onLoad={decodeQRCode}
              style={{ width: "100%", height: "100%" }}
            />
            <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          </>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <Typography variant="body-large" color={theme.palette.lightBg.low}>
        Click ‘Next‘ once you scan the code
      </Typography>
      <Typography
        variant="button-large"
        color={theme.palette.primary.main}
        sx={{ cursor: "pointer" }}
        onClick={() => {
          navigate("/mfa/cant-scan-qrcode", {
            state: { recoveryCode, loginData, userId, mfaEnrolled, backupCode },
          });
        }}
      >
        Can’t scan image ?
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={"1fr 1fr"}
        direction="row"
        gap="16px"
        width={"60%"}
      >
        <ButtonWithLoadingState
          variant="secondary"
          onClick={() => navigate("/login")}
        >
          Back
        </ButtonWithLoadingState>
        <ButtonWithLoadingState
          onClick={() =>
            navigate("/mfa/verify-account", {
              state: { loginData, userId, mfaEnrolled },
            })
          }
        >
          Next
        </ButtonWithLoadingState>
      </Box>
    </Stack>
  );
};
