import { getCountriesService } from "../services";

const getCountries = async () => {
  try {
    const response = await getCountriesService();
    const data = response?.data;
    if (
      data.statusCode === 200 &&
      data.success &&
      Array.isArray(data?.data?.result)
    ) {
      return data.data.result.map((dataItem) => ({
        key: dataItem.id,
        value: dataItem.id,
        label: dataItem.name,
      }));
    } else {
      throw response;
    }
  } catch (error) {
    throw error;
  }
};

export { getCountries };
