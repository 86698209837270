import { axiosClient } from "../utils";

export const getAuthAccessToken = async () => {
  const result = await axiosClient.get("/refresh/token", {
    headers: {
      "x-refresh-token": `${document.cookie}`,
    },
  });
  return result;
};
