import { AspectRatio } from "@mui/icons-material";
import { Box } from "@mui/material";

function ArrowIcon(props) {

    function getAngle(direction) {
        switch (direction) {
            case 'bottom':
                return '270deg';
            case 'right':
                return '180deg';
            case 'top':
                return '90deg';
            default:
                return '0deg';
        }
    }

    return (
        <Box
            sx={{
                transform: `rotate(${getAngle(props.direction)})`,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                AspectRatio: '1',
                justifyContent: 'center',
            }}
        >


            <svg
                width="50%"

                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 1L3 5L7 9"
                    stroke={props.disabled === true ? props.disabledcolor : props.color}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round" />
            </svg>



        </Box>
    );
}
export default ArrowIcon;