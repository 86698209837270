function slaIcon(props) {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.99667 13.3319V6.66241C7.99667 5.18903 9.19108 3.99463 10.6645 3.99463H25.3372C26.8106 3.99463 28.005 5.18903 28.005 6.66241V25.3368C28.005 26.8102 26.8106 28.0046 25.3372 28.0046H20.0017"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M13.3322 9.33052H22.6695"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M16 14.666H22.6694"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M22.6695 20.0014H20.0017"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5841 27.9716H4.74067C4.54291 27.9716 4.35326 27.8931 4.21342 27.7532C4.07358 27.6134 3.99503 27.4238 3.99503 27.226V27.226V21.3809C3.99503 21.1831 4.07358 20.9935 4.21342 20.8536C4.35326 20.7138 4.54291 20.6353 4.74067 20.6353H6.5841C6.78541 20.6384 6.97721 20.7214 7.1173 20.866C7.25739 21.0106 7.33429 21.2049 7.33108 21.4062V27.2754C7.30963 27.6705 6.97974 27.978 6.5841 27.9716V27.9716Z"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
            <path
                d="M7.32974 22.2156L9.79477 18.1912C10.2159 17.5063 11.04 17.1835 11.8143 17.4003C12.5886 17.6171 13.1253 18.3209 13.1295 19.125V21.2592H15.1437C15.749 21.2595 16.3139 21.563 16.6483 22.0675C16.9459 22.5149 17.0322 23.0703 16.8844 23.5868L15.908 27.0042C15.7427 27.5803 15.2161 27.9774 14.6168 27.978H9.84012C9.45057 27.978 9.07324 27.842 8.77301 27.5938L7.32974 26.3933"
                stroke={props.disabled === true ? props.disabledcolor : props.color}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round" />
        </svg>

    );
}
export default slaIcon;